<template>
  <div class="advantage">
    <u-animate-container>
      <u-animate name="fadeInDown" delay="0.5s" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
        <img class="title" :src="advantageImgs.title"/>
      </u-animate>
    </u-animate-container>
    <u-animate-container>
      <u-animate name="fadeInDown" delay="0.5s" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
        <img class="sub_title" :src="advantageImgs.sub_title"/>
      </u-animate>
    </u-animate-container>
    <u-animate-container>
      <u-animate name="fadeIn" delay="0.5s" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
        <img class="persona" :src="advantageImgs.persona"/>
      </u-animate>
    </u-animate-container>
    <u-animate-container>
      <u-animate name="fadeIn" delay="1s" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
        <img class="bg" :src="advantageImgs.bgImg"/>
      </u-animate>
    </u-animate-container>
    <u-animate-container>
      <u-animate name="fadeInLeft" delay="0.5s" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
        <img class="virtual" :src="advantageImgs.virtual"/>
      </u-animate>
    </u-animate-container>
    <u-animate-container>
      <u-animate name="fadeInRight" delay="0.5s" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
        <img class="saas" :src="advantageImgs.saas"/>
      </u-animate>
    </u-animate-container>
    <u-animate-container>
      <u-animate name="fadeInLeft" delay="0.5s" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
        <img class="anchor" :src="advantageImgs.anchor"/>
      </u-animate>
    </u-animate-container>
    <u-animate-container>
      <u-animate name="fadeInUp" delay="1s" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
        <img class="Live_broadcasting_room" :src="advantageImgs.Live_broadcasting_room"/>
      </u-animate>
    </u-animate-container>
    <u-animate-container>
      <u-animate name="fadeInRight" delay="0.5s" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
        <img class="Live" :src="advantageImgs.Live"/>
      </u-animate>
    </u-animate-container>
  </div>
</template>

<script>
import { advantageImgs } from '@/assets/js/fixed_data'

export default {
  name: "advantage",
  data() {
    return {
      advantageImgs: advantageImgs
    }
  }
}
</script>

<style scoped lang="less">
.advantage {
  width: 1200px;
  margin: auto;
  height: 6.4rem;
  //padding-bottom: 4rem;

  .title {
    width: 400px;
    position: absolute;
    left: 50%;
    margin-left: -200px;
    margin-top: .4rem;
  }

  .sub_title {
    width: 180px;
    position: absolute;
    left: 50%;
    margin-left: -90px;
    margin-top: 1.1rem;
  }

  .persona {
    width: 400px;
    position: absolute;
    left: 50%;
    margin-left: -200px;
    margin-top: 2.32rem;
    z-index: 2;
  }

  .bg {
    width: 400px;
    position: absolute;
    left: 50%;
    margin-top: 1.8rem;
    margin-left: -200px;
  }

  .virtual {
    width: 260px;
    position: absolute;
    left: 50%;
    margin-left: -4rem;
    margin-top: 1.6rem;
  }

  .saas {
    width: 260px;
    position: absolute;
    right: 50%;
    margin-right: -4rem;
    margin-top: 1.6rem;
  }

  .anchor {
    width: 240px;
    position: absolute;
    left: 50%;
    margin-left: -4.2rem;
    margin-top: 3.5rem;
  }

  .Live {
    width: 240px;
    position: absolute;
    right: 50%;
    margin-right: -4.2rem;
    margin-top: 3.5rem;
  }

  .Live_broadcasting_room {
    width: 320px;
    position: absolute;
    left: 50%;
    margin-left: -160px;
    margin-top: 4.6rem;
    z-index: 3;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .advantage {
    width: 100%;
    height: 5.7rem;

    .title {
      width: 320px;
      margin-left: -160px;
      margin-top: .4rem;
    }

    .sub_title {
      width: 140px;
      margin-left: -70px;
      margin-top: 1rem;
    }

    .persona {
      width: 350px;
      margin-left: -175px;
      margin-top: 2.2rem;
    }

    .bg {
      width: 360px;
      margin-left: -180px;
      margin-top: 1.6rem;
    }

    .virtual {
      width: 220px;
      margin-left: -3.4rem;
      margin-top: 1.4rem;
    }

    .saas {
      width: 220px;
      margin-right: -3.4rem;
      margin-top: 1.4rem;
    }

    .anchor {
      width: 220px;
      margin-left: -3.9rem;
      margin-top: 3rem;
    }

    .Live {
      width: 220px;
      margin-right: -3.9rem;
      margin-top: 3rem;
    }

    .Live_broadcasting_room {
      width: 280px;
      margin-left: -140px;
      margin-top: 4.2rem;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .advantage {
    width: 100%;
    height: 5.4rem;

    .title {
      width: 280px;
      margin-left: -140px;
      margin-top: .4rem;
    }

    .sub_title {
      width: 120px;
      margin-left: -60px;
      margin-top: 1rem;
    }

    .persona {
      width: 330px;
      margin-left: -165px;
      margin-top: 2.02rem;
    }

    .bg {
      width: 340px;
      margin-left: -170px;
      margin-top: 1.5rem;
    }

    .virtual {
      width: 200px;
      margin-left: -3.2rem;
      margin-top: 1.4rem;
    }

    .saas {
      width: 200px;
      margin-right: -3.2rem;
      margin-top: 1.4rem;
    }

    .anchor {
      width: 180px;
      margin-left: -3.4rem;
      margin-top: 3rem;
    }

    .Live {
      width: 180px;
      margin-right: -3.4rem;
      margin-top: 3rem;
    }

    .Live_broadcasting_room {
      width: 260px;
      margin-left: -130px;
      margin-top: 3.9rem;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .advantage {
    width: 100%;
    height: 3.4rem;

    .title {
      width: 180px;
      margin-left: -90px;
      margin-top: .4rem;
    }

    .sub_title {
      width: 80px;
      margin-left: -40px;
      margin-top: .8rem;
    }

    .persona {
      width: 180px;
      margin-left: -90px;
      margin-top: 1.5rem;
    }

    .bg {
      width: 180px;
      margin-left: -90px;
      margin-top: 1.2rem;
    }

    .virtual {
      width: 120px;
      margin-left: -1.6rem;
      margin-top: 1.1rem;
    }

    .saas {
      width: 120px;
      margin-right: -1.6rem;
      margin-top: 1.1rem;
    }

    .anchor {
      width: 100px;
      margin-left: -1.8rem;
      margin-top: 2rem;
    }

    .Live {
      width: 100px;
      margin-right: -1.8rem;
      margin-top: 2rem;
    }

    .Live_broadcasting_room {
      width: 140px;
      margin-left: -70px;
      margin-top: 2.5rem;
    }
  }
}
</style>
