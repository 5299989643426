<template>
  <div>
    <page-guide :options="options" :slogan="slogan" :pc-image="backgroundImage.pcImage" :mobile-image="backgroundImage.mobileImage"></page-guide>

    <introduction :productIntroduction="productIntroduction"></introduction>

    <div class="advantageBox">
      <advantage></advantage>
    </div>
    <div class="displaySpace">
      <u-animate-container>
        <u-animate name="zoomIn" delay="0s" duration="0.5s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
          <div class="titleBox">
            <p class="title1">虚拟直播展示</p>
            <span class="title1-line"></span>
            <p class="title2">Anchor</p>
          </div>
        </u-animate>
      </u-animate-container>

      <video_swiper class="videoContainer" :videoList="videoList"></video_swiper>
    </div>

    <contact-us></contact-us>

    <m-footer></m-footer>
  </div>
</template>

<script>
import pageGuide from '@/components/page-guide';
import introduction from "@/components/introduction";
import advantage from "./advantage"
import video_swiper from "@/components/video-swiper";
import contactUs from "@/components/contact-us";
import footer from "@/components/footer";
import { ProductIntroduction, virtualVideoList } from "@/assets/js/fixed_data"

export default {
  name: "index",
  components: {
    pageGuide,
    introduction,
    advantage,
    video_swiper,
    contactUs,
    'm-footer': footer
  },
  data() {
    return {
      options: [{
        content: 'Magics',
        animate: 'fadeIn',
        delay: '0s'
      }, {
        content: '智能',
        animate: 'fadeIn',
        delay: '0.8s'
      }, {
        content: '虚拟',
        animate: 'fadeIn',
        delay: '0.5s'
      }, {
        content: '直播',
        animate: 'fadeIn',
        delay: '0.7s'
      }],
      slogan: '打造有得看 · 有得聊 · 有流量的直播间',
      backgroundImage: {
        pcImage: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/background/bg1.png',
        mobileImage: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/background/01A.png'
      },
      productIntroduction: ProductIntroduction.liveBroadcast,
      videoList: virtualVideoList
    }
  }
}
</script>

<style scoped lang="less">
.advantageBox{
  width: 100%;
  background: #F8F8FF;
}
.displaySpace {
  width: 1200px;
  height: 7.29rem;
  margin: auto;

  .videoContainer {
    //width: 16rem;
    margin: 0 auto;
  }
}

.titleBox {
  text-align: center;
  padding: .5rem 0 .43rem 0;
  font-size: 0;
  color: #333333;
  margin: 0 auto;

  &:hover {
    cursor: pointer;
  }

  .title1 {
    text-align: center;
    font-size: .32rem;
    font-weight: 500;
  }

  .title1-line {
    display: block;
    margin: 0 auto;
    width: 70px;
    height: 3px;
    background-color: #5b5af3;
    border-radius: 20px;
    transform: rotateY(90deg);
    transition: all 1s;
  }

  &:hover .title1-line {
    transform: rotateY(0deg);
  }

  .title2 {
    font-size: 21px;
    font-family: Impact, Charcoal, sans-serif;
    font-weight: normal;
    color: #7776ff33;
    line-height: 31px;
    letter-spacing: 3px;
    font-style: italic;
    margin-top: 0.06rem;
    display: inline-block;
  }
}

@media screen and (min-width: 320px) and (max-width: 992px) {
  .displaySpace {
    width: 100%;
    height: 5.8rem;
    margin: 0 auto;

    .videoContainer {
      width: 100%;
      margin: 0 auto;
    }
  }

  .titleBox {
    padding: 0.3rem 0 0.1rem;

    .title1 {
      font-size: 20px;
    }

    .title2 {
      margin-top: 0;
      font-size: 14px;
      letter-spacing: 2px;
    }
  }
}
</style>
