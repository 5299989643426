<template>
  <div class="introductionBox" :style="{flexDirection:direction.flexDirection}">
    <u-animate-container>
      <u-animate :name="direction.imgAnimate" delay="0.5s" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
        <img class="mul-img" :src="productIntroduction.mulImg" alt="">
      </u-animate>
    </u-animate-container>
    <u-animate-container>
      <u-animate :name="direction.contentAnimate" delay="0.5s" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
        <div class="content" :style="direction.margin">
          <div class="title_hea">
            <p>{{ productIntroduction.title }}</p>
            <span class="spanUnderline" :style="{background:productIntroduction.lineColor}"></span>
          </div>
          <div class="title_sub" v-html="productIntroduction.content">
            <!--            {{ productIntroduction.content }}-->
          </div>
          <el-button v-if="showMore" class="more-btn" type="text" @click.stop="knowMore(productIntroduction.name)">
            了解详情
            <i class="el-icon-d-arrow-right"></i>
          </el-button>
        </div>
      </u-animate>
    </u-animate-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "introduction",
  props: {
    productIntroduction: Object,
    mulImg: String,
    type: {
      type: String,
      default: 'left'
    },
    showMore: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['clientWidth']),
    direction() {
      let Direction = {
        flexDirection: '',
        imgAnimate: 'fadeInLeft',
        contentAnimate: 'fadeInRight',
        margin: ''
      }
      switch (this.type) {
        case 'left':
          Direction.flexDirection = 'row'
          Direction.imgAnimate = 'fadeInLeft'
          Direction.contentAnimate = 'fadeInRight'
          Direction.margin = 'margin-left:1rem'
          break;
        case 'right':
          Direction.flexDirection = 'row-reverse'
          Direction.imgAnimate = 'fadeInRight'
          Direction.contentAnimate = 'fadeInLeft'
          Direction.margin = 'margin-right:1rem'
          break
      }
      if (this.clientWidth < 992) {
          Direction.flexDirection = 'column'
          Direction.margin = 'margin:0'
      }
      return Direction
    }
  },
  methods: {
    knowMore(name) {
      this.$router.push({ name: name })
    }
  }
}
</script>

<style lang="less" scoped>
.introductionBox {
  width: 1200px;
  margin: 1rem auto;
  //height: 6.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  //flex-direction: row;

  &:hover .spanUnderline {
    width: 33%;
  }

  &:hover .mul-img {
    transform: scale(1.05);
  }

  &:hover .more-btn {
    transform: scale(1);
  }

  .mul-img {
    width: 4.12rem;
    transition: 1s;
  }

  .content {
    flex: 1;
    //margin-left: 1rem;

    .title_hea {
      font-size: 28px;
      color: #333333;
      font-weight: 400;
    }

    .title_sub {
      text-align: left;
      font-size: 18px;
      font-weight: 400;
      color: #666666;
      line-height: .3rem;
    }
  }

  .spanUnderline {
    display: inline-block;
    width: .75rem;
    height: 4px;
    //background: #648EE8;
    border-radius: 3px;
    margin: 14px 0 26px;
    transition: width 1s;
  }

  .more-btn {
    font-size: 18px;
    color: #7776FF;
    -webkit-transform: scale(0.93);
    transform: scale(0.93);
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }
}

.experience {
  font-size: 18px;
  color: #7776FF;
  -webkit-transform: scale(0.93);
  transform: scale(0.93);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;

  &:hover {
    transform: scale(1);
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .introductionBox {
    width: 94%;
  }
}

@media screen and (min-width: 320px) and (max-width: 991px) {
  .introductionBox {
    width: 100%;
    margin: 0.5rem auto;

    .mul-img {
      height: 2.49rem;
      width: auto;
      transition: .5s;
    }

    .content {
      margin-top: 0;
      margin-left: 0;
      padding: 0 24px;

      .title_hea {
        text-align: center;
        font-size: 20px;
        font-weight: 500;

        .spanUnderline {
          margin: .1rem 0 .2rem;
        }
      }

      .title_sub {
        font-size: 14px;
        line-height: .24rem;
      }

      .more-btn {
        font-size: 15px;
        margin-top: 0;
      }
    }
  }
}
</style>
